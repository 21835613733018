import React from 'react';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import type { AppProps } from 'next/app';
import { datadogRum } from '@datadog/browser-rum';

import { datadogInit } from '@/utils/datadog';
import { datadogLogs } from '@datadog/browser-logs';
import '../blocks/CookieDeclarationSection/CookieDeclaration.css';
import '../styles/globals.css';
import '../blocks/JobDetails/JobDetails.css';
import '../blocks/ImageSet/ImageSet.css';

storyblokInit({
  accessToken: process.env.storyblokApiToken,
  use: [apiPlugin],
});

function App({ Component, pageProps }: AppProps) {
  const isProduction = () => {
    return (
      window.location.hostname === 'www.gostudent.org' ||
      window.location.hostname === 'hi.gostudent.org'
    );
  };

  const handleAcceptCookies = () => {
    if (window.Cookiebot.consent.statistics && isProduction()) {
      datadogRum.setTrackingConsent('granted');
      datadogLogs.setTrackingConsent('granted');
    }
  };

  const handleDeclineCookies = () => {
    datadogRum.setTrackingConsent('not-granted');
    datadogLogs.setTrackingConsent('not-granted');
  };

  React.useEffect(() => {
    if (process.env.staticPagesBuild === 'true') {
      datadogInit();

      window.addEventListener('CookiebotOnAccept', handleAcceptCookies);
      window.addEventListener('CookiebotOnDecline', handleDeclineCookies);

      return () => {
        window.removeEventListener('CookiebotOnAccept', handleAcceptCookies);
        window.removeEventListener('CookiebotOnDecline', handleDeclineCookies);
      };
    }
  }, []);

  return <Component {...pageProps} />;
}

export default App;
