import { LogsInitConfiguration, datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';

const datadogRUMConfig: RumInitConfiguration = {
  applicationId: '0ea437cc-cd4a-4582-863c-eb9017dca761',
  clientToken: 'pub5ec968e33fcf36ff4cfa18ad6f5071e9',
  defaultPrivacyLevel: 'mask-user-input',
  env: process.env.gostudentEnv || 'prod',
  service: 'martech-static-page-generator',
  sessionReplaySampleRate: +(process.env.datadogSessionReplaySampleRate || 1),
  sessionSampleRate: +(process.env.datadogSessionSampleRate || 100),
  site: 'datadoghq.eu',
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
  trackingConsent: 'not-granted',
  version: process.env.staticBuildId || 'unknown',
};

const datadogLogsConfig: LogsInitConfiguration = {
  clientToken: 'pub9f79bb80037838f49eeb854340f0f266',
  env: 'prod',
  forwardErrorsToLogs: true,
  service: 'martech-static-page-generator',
  sessionSampleRate: +(process.env.datadogSessionSampleRate || 100),
  site: 'datadoghq.eu',
  trackingConsent: 'not-granted',
  version: process.env.staticBuildId || 'unknown',
};

export const datadogInit = () => {
  datadogRum.init(datadogRUMConfig);
  datadogLogs.init(datadogLogsConfig);
};
